
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import SwitchStatus from "@/components/scope/switch-status.vue";
import { Table, TableRowsSelect } from "@/utils/decorator";
import StandardFlag from "@/components/scope/standard-flag.vue";
import t from "@common/src/i18n/t";
const detecationTemplate = namespace("detecationTemplate");
const base = namespace("base");
@Component({
  components: { SwitchStatus, StandardFlag }
})
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("test.select-test-template"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
export default class TestingItemList extends Vue {
  @detecationTemplate.Action getDetecationTemplateist;
  @detecationTemplate.Action publishDetecationTemplate;
  @detecationTemplate.Action offShelfDetecationTemplate;
  @base.Action getDictionaryList;


  get searchList() {
    return [
      {
        label: "养车模板名称",
        type: "input",
        value: "",
        prop: "templateName"
      }
    ];
  }

  templateList: any[] = [];

  searchForm = {
    templateName: ""
  };
  emptyToLine = emptyToLine;

  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }

  @Table.LoadDataMethod()
  loadListData() {
    return this.getDetecationTemplateist({
      ...this.searchForm,
      current: this.mix_pageNum,
      size: this.mix_pageSize
    }).then(data => {
      this.templateList = data.data.list;
      return data;
    });
  }
  async init() {
    this.loadListData();
  }
  handleEdit(row) {
    this.$router.push(`/maintenance/detecationTemplate/edit/${row.templateId}`);
  }
  handleView(row) {
    this.$router.push(`/maintenance/detecationTemplate/view/${row.templateId}`);
  }

  handlePublish(row) {
    return this.publishDetecationTemplate({
      templateId: row.templateId
    }).then(data => {
      this.$message.success("发布成功");
      this.loadListData();
    });
  }

  handleOffshelf(row) {
    return this.offShelfDetecationTemplate({
      templateId: row.templateId
    }).then(data => {
      this.$message.success("下架成功");
      this.loadListData();
    });
  }

  created() {
    this.init();
  }
}
